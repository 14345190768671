import React, { useEffect, useState } from 'react';
import companyGeneralDataReducer from '../../redux/companyGeneralData';
import withReducers from '../../store/withReducers';
import useCompanyGeneralData from './hooks/useCompanyGeneralData';
import moment from 'moment';
import { AlertNotification, ArrowIcon, Button, Col, colors, Label, LabeledCheckbox, LabeledRadioInButton, Link, Loading, Row, Typography, XIcon } from '@commonsku/styles';
import Features from '../../constants/Features';
import { isPast, oauth } from '../../utils';
import { useHasCapabilities } from '../../hooks';

type AdminBillingRenewalDetailsProps = {
  onClose: (thankYou: boolean) =>  void;
  identity: any;
}

function AdminBillingRenewalDetails({
  onClose,
  identity
}: AdminBillingRenewalDetailsProps) {
    const [ { data }, companyLoading, save ] = useCompanyGeneralData();
    const [renewalFeatureId, setRenewalFeatureId] = useState<string | undefined>();
    const [renewalPaymentModel, setRenewalPaymentModel] = useState<string | undefined>();
    const [renewalLicenses, setRenewalLicenses] = useState<string | undefined>();
    const [acceptedTerms, setAcceptedTerms] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [thankYou, setThankYou] = useState<boolean>(false);
    const [stripeLoading, setStripeLoading] = useState<boolean>(true);
    const [contractedLicenses, setContractedLicenses] = useState<number | undefined>();
    const [pendingLicenses, setPendingLicenses] = useState<number | undefined>();
    const [activeUsers, setActiveUsers] = useState<number | undefined>();
    const [error, setError] = useState<boolean>(false);
    const isFull = useHasCapabilities('FEATURE-FULL');
    const isSocial = useHasCapabilities('FEATURE-SOCIAL');
    const onContract: boolean = !!data.contracted_year;
    const pastMigrationDueDate: boolean = !data.migration_due_date ? true : isPast(data.migration_due_date);
    const migrationDueDate: string = !data.migration_due_date ? null : moment(data.migration_due_date).format(`MMM [1st] YYYY`);
    const migrationDueFullDate: string = moment(data.migration_due_date).format(`MMMM [1st] YYYY`);
    const hideRemoveUnusedLicensesOption = (isFull && data.payment_model === 'MONTH') ||
      (pastMigrationDueDate && !onContract && isSocial);
    const dataLoading = companyLoading !== 'idle' || stripeLoading;

    useEffect(() => {
      if (data.renewal_feature_id && data.renewal_date) {
        setRenewalFeatureId(data.renewal_feature_id);
        setRenewalPaymentModel(data.renewal_payment_model);
        setRenewalLicenses(data.renewal_licenses);
      }
    }, [ data ]);

    useEffect(() => {
      if (saved && !onContract) {
        setError(true);
      }

      if (saved && onContract) {
        setThankYou(true);
      }
    }, [ onContract, saved ]);

    useEffect(() => {
      oauth('GET', 'stripe', {}).then(
        ({ json }) => {
          setStripeLoading(false);
          const { stripe_info: stripeInfo, active_users: activeUsers } = json || {};
          if (!stripeInfo.stripe_subscription_item) {
            setError(true);
            return;
          }
          setActiveUsers(activeUsers);
          setContractedLicenses(stripeInfo.stripe_subscription_item.quantity);
          setPendingLicenses(stripeInfo.stripe_pending_quantity_change?.quantity);
        },
        () => setError(true)
      );
    }, []);

    let title: string;
    let info: string;
    let terms: React.ReactNode;
    let cancelText: string;
    let featureLevelLabel: string | React.ReactNode;
    let renewalInfo: string;

    if (onContract) {
        const nextRenewal = moment(pastMigrationDueDate ? data.renewal_date : data.migration_due_date).format("MMM [1st] YYYY")
        title = `Your contract will ${pastMigrationDueDate ? 'automatically renew' : 'start'} on ${nextRenewal}`;
        info = 'Use the settings below to set your license renewal preferences.';
        terms = <div>I'm authorized to make changes to our contract</div>;
        cancelText = 'Cancel';
        featureLevelLabel = 'Feature Level';
        const currentLicenses = isFull && pendingLicenses ? pendingLicenses : contractedLicenses;
        const renewingLicenses = data.renewal_licenses === 'ACTIVE_USERS' ? Math.max(activeUsers, 2) : contractedLicenses;
        renewalInfo = pastMigrationDueDate ?
          `You currently have ${currentLicenses} contracted licenses, ${renewingLicenses === currentLicenses ? 'all' : renewingLicenses} scheduled to renew, and ${activeUsers === currentLicenses ? 'all' : activeUsers} in use by assigned users.` :
          `You are committing to ${currentLicenses} licenses.`;
    } else {
        title = `You will be starting an annual contract on ${migrationDueDate}`;
        info = `Select your options for your commonsku contract.${!pastMigrationDueDate ? ` You will be able to change your choices at any time until ${migrationDueDate} in the admin settings area.` : ''}`;
        terms = <div style={{width: '100%'}}>I have read, understand and accept the <Link as="a" style={{ color: colors.primary1.main }} href="http://www.commonsku.com/terms" target="_blank">commonsku Terms of Service</Link>. By clicking "Save" below, I agree that commonsku is authorized to charge me for all fees due during the Subscription Term and any renewal term. I certify that I am authorized to sign and enter into this binding legal contract for the company or organization making this purchase.</div>
        cancelText = 'Do this later';
        featureLevelLabel = <>Select your new plan. Recommendation is based on current feature usage. <Link as={'a'} href={'https://commonsku.com/pricing'} target={'_blank'}>Learn More</Link></>;
        let renewingLicenses = pendingLicenses ?? contractedLicenses;
        if (isFull && data.payment_model === 'YEAR') {
          renewingLicenses = renewalLicenses === 'ACTIVE_USERS' ? Math.max(activeUsers, 2) : contractedLicenses;
        }

        renewalInfo = `You are committing to ${renewingLicenses} licenses.`;
    }
    
    const onSave = async () => {
      await save({
        action: 'contract-changes',
        distributorId: data.tenant_id,
        renewalFeatureId,
        renewalPaymentModel,
        renewalLicenses,
      }, !!identity.contracted_year);

      setSaved(true);

      if (identity.contracted_year) {
        onClose(false);
      }
    };

    const message = error ? 'An error occurred during your account configuration, please contact commonsku support.' :
      (
        !pastMigrationDueDate ?
          `If you need to make changes to your selections before ${migrationDueFullDate}, you can do so in the Billing tab of your Admin Settings area.` :
          'You are all set. Your account configuration has been updated.'
      );

    const content = !dataLoading && (thankYou || error) ? <>
      <p style={{margin: '65px 100px', fontSize: 24, textAlign: 'center'}}>
        {message}
      </p>
    </> : <>
      <AlertNotification
        href='https://help.commonsku.com/knowledge/essentials-advanced'
        learnMore={true}
        target={'_blank'}
      >
        {info}
      </AlertNotification>
      <Col style={{minHeight: !onContract ? 395 : (!hideRemoveUnusedLicensesOption ? 370 : 325)}}>
        {dataLoading ? <Row pt={175} style={{minHeight: 395}}><Loading /></Row> : <>
          <Row mt={20}>
            <Label mb={5}>{featureLevelLabel}</Label>
            <LabeledRadioInButton label='Essentials' checked={renewalFeatureId === Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID} onChange={()=>setRenewalFeatureId(Features.DISTRIBUTOR_ESSENTIAL_FEATURE_ID)} />
            <LabeledRadioInButton label='Advanced' checked={renewalFeatureId === Features.DISTRIBUTOR_ADVANCED_FEATURE_ID} onChange={()=>setRenewalFeatureId(Features.DISTRIBUTOR_ADVANCED_FEATURE_ID)} />
          </Row>

          <Row mt={10}>
            <Label mb={5}>Would you like to pay annually (with a discount) or monthly?</Label>
            <LabeledRadioInButton label='Monthly' checked={renewalPaymentModel === 'MONTH'} onChange={()=>setRenewalPaymentModel('MONTH')} />
            <LabeledRadioInButton label='Annually 10% discount' checked={renewalPaymentModel === 'YEAR'} onChange={()=>setRenewalPaymentModel('YEAR')} />
          </Row>

          {!hideRemoveUnusedLicensesOption &&
            <Row mt={10}>
              <LabeledCheckbox
                label={'Remove unused licenses upon renewal'}
                checked={renewalLicenses === 'ACTIVE_USERS'}
                onChange={(e) => setRenewalLicenses(!e.target.checked ? 'CONTRACTED_LICENSES' : 'ACTIVE_USERS')}
              />
            </Row>
          }

          <Row mt={10}>
            <p>{renewalInfo}</p>
          </Row>
          <Row mt={20}>
            <LabeledCheckbox
              label={terms}
              checked={acceptedTerms}
              onChange={()=>setAcceptedTerms(!acceptedTerms)}
            />
          </Row>
        </>}
      </Col>
    </>;

    const allowClosing = !pastMigrationDueDate || onContract || thankYou || error;

    return (
        <>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            {thankYou && !pastMigrationDueDate &&
              <ArrowIcon cursor={'pointer'} onClick={() => setThankYou(false)} mt={2} mr={10} direction='left' size='large' />
            }
            <Typography.H2 style={{marginBottom: 10}}>{thankYou ? 'Thank you!' : title}</Typography.H2>
            {allowClosing &&
              <XIcon cursor={'pointer'} onClick={() => onClose(thankYou)} mt={2} ml={10} size='large' />
            }
          </div>
          {content}
          <Col>
            <Row justify={'right'} mt={20}>
              {allowClosing &&
                <Button
                  variant={thankYou || error ? 'primary' : 'secondary'}
                  onClick={() => onClose(thankYou)}
                  mr={!thankYou ? 10 : 0}
                  disabled={dataLoading}
                >
                  {thankYou || error ? 'Done' : cancelText}
                </Button>
              }
              {!thankYou && !error &&
                <Button
                  onClick={() => onSave()}
                  disabled={!acceptedTerms || dataLoading}
                >Save</Button>
              }
            </Row>
          </Col>
      </>
    );
}

export default withReducers(AdminBillingRenewalDetails, {
  companyGeneralData: companyGeneralDataReducer,
});
