import {
    createSlice,
} from '@reduxjs/toolkit';
import { oauth } from '../utils';

const slice = createSlice({
    name: 'companyGeneralData',
    initialState: {
        loading: 'idle',
        data: {}
    },
    reducers: {
        loading(state, action) {
            if (state.loading === 'idle') {
                state.loading = 'pending';
            }
        },
        loaded(state, action) {
            if (state.loading === 'pending') {
                state.loading = 'idle';
            }
        },
        setData(state, action) {
            state.data = {...state.data, ...action.payload};
            state.loading = 'idle';
        },
    }
});

export const {
    setData,
    loading,
    loaded
} = slice.actions;

/**
 * The actions (thunk)
 */
export const getData = (id, type = 'tenant') => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', `${type}/${id}`,  {});
    dispatch(setData(res.json[type]));
    return res.json[type];
};

export const updateData = (id, type = 'tenant', data) => async (dispatch, getState) => {
    dispatch(loading());
    let res = {};
    try {
        res = await oauth('PUT', `${type}/${id}`, data);
    } catch (err) {
        console.log('Failed to update', err.json.error);
        dispatch(loaded());
        return err.json.error;
    }
    data = (type === 'tenant') && res.json.distributor ? res.json.distributor : res.json[type]
    dispatch(setData(data));
    return true;
};

export const updateFeature = (distributorId, featureId, paymentModel, applyAdvancedCoupon) => async (dispatch, getState) => {
    dispatch(loading());
    let res = {};
    try {
        res = await oauth('PUT', `tenant`, {
            action: 'feature-level',
            distributorId,
            featureId,
            paymentModel,
            applyAdvancedCoupon,
        });
    } catch (err) {
        throw err;
    } finally {
        dispatch(loaded());
    }

    dispatch(setData(res.json.distributor));
};

export const getExtraData = (id) => async (dispatch, getState) => {
    dispatch(loading());
    const res = await oauth('GET', `company-data/${id}`,  {});
    dispatch(setData({companyData: res.json.company_data}));
    return res.json.company_data;
};

export const updateExtraData = (id, data) => async (dispatch, getState) => {
    dispatch(loading());
    let res = {};
    try {
        res = await oauth('PUT', `company-data/${id}`, data);
    } catch (err) {
        console.log('Failed to update');
        dispatch(loaded());
        return err.json.error;
    }
    dispatch(setData({companyData: res.json.company_data}));
    return true;
};

export default slice.reducer;
