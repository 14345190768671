const Features = {
    DISTRIBUTOR_FULL_FEATURE_ID: 'f59bea0f-e807-11e2-adb6-e811324a6a81',
    DISTRIBUTOR_SOCIAL_FEATURE_ID: 'f50cd437-e807-11e2-adb6-e811324a6a81',
    DISTRIBUTOR_ESSENTIAL_FEATURE_ID: 'f9e5e614-32ed-11ef-9e25-302b4bf4e8ab',
    DISTRIBUTOR_ADVANCED_FEATURE_ID: 'f9e5eb82-32ed-11ef-9e25-302b4bf4e8ab',
    DISTRIBUTOR_ENTERPRISE_FEATURE_ID: 'f9e60ee6-32ed-11ef-9e25-302b4bf4e8ab',
    SUPPLIER_DORMANT_FEATURE_ID: '50d09f50-c49b-11e7-b6c6-a78bc432adcf',
    SUPPLIER_COMMUNITYBASIC_FEATURE_ID: '145ca04b-7270-11ed-983b-0ad1fa230847',
    SUPPLIER_COMMUNITYPLUS_FEATURE_ID: '19d58508-7270-11ed-983b-0ad1fa230847',
    SUPPLIER_CONNECTEDBASIC_FEATURE_ID: '1e2b78d1-7270-11ed-983b-0ad1fa230847',
    SUPPLIER_CONNECTEDPLUS_FEATURE_ID: '21ec7285-7270-11ed-983b-0ad1fa230847',
};

export default Features;
